import styled, { css } from 'styled-components';

import { devices } from '../../styles';

const Title = styled.h2`
  font-size: 30px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 24px;
`;

const Description = styled.p`
  margin-bottom: 24px;
`;

const CloseButton = styled.button`
  cursor: pointer;
  position: absolute;
  outline: 0 none;
  top: 25px;
  right: 25px;
  width: 30px;
  height: 30px;
  color: ${({ theme }) => theme.text.standard};
  border: 0;
  background-color: transparent;
  font-size: 30px;
  z-index: 20001;
`;

const PurchaseModal = styled.div`
  position: fixed;
  width: 480px;
  right: -480px;
  bottom: 0;
  top: 0;
  background: ${({ theme }) => theme.background.standard};
  z-index: 20000;
  border-left: 2px solid ${({ theme }) => theme.nav.border};
  padding: 48px;
  transition: right 0.38s ease-in-out, box-shadow 0.38s ease-in;
  overflow-y: auto;

  ${({ isModalOpen }) =>
    isModalOpen &&
    css`
      box-shadow: -18px 0 41px 6px rgba(0, 0, 0, 0.17);
      right: 0;

      @media ${devices.mobile} {
        width: 100%;
      }
    `};
`;

PurchaseModal.Title = Title;
PurchaseModal.Description = Description;
PurchaseModal.CloseButton = CloseButton;

export default PurchaseModal;
